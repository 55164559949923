<template>
  <v-list-item
    :href="item.href"
    :rel="item.href ? 'nofollow' : undefined"
    :target="item.href ? '_blank' : undefined"
    @click="complexClickHandler(item)"
    active-class="primary grey--text"
    link
    class="py-1"
    v-bind="$attrs"
    :prepend-icon="item.icon"
    :title="item.title"
  >
    <!-- <v-list-item-icon
        v-if="!item.icon"
        class="text-caption text-uppercase justify-center ml-1 my-2 align-self-center"
      >
        {{ title }}
      </v-list-item-icon>

      <v-list-item-avatar v-if="item.avatar">
        <v-img :src="item.avatar" />
      </v-list-item-avatar>

      <v-list-item-icon
        v-if="item.icon"
        class="my-2 align-self-center"
      >
        <v-icon v-text="item.icon" />
      </v-list-item-icon>

      <v-list-item-content v-if="item.title">
        <v-list-item-title v-text="item.title" />
      </v-list-item-content> -->
  </v-list-item>
</template>

<script>
export default {
  name: "DefaultListItemXajax",

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    title() {
      const matches = this.item.title.match(/\b(\w)/g);

      return matches.join("");
    }
  },
  methods: {
    complexClickHandler(item) {
      // document.getElementById('iframe1').contentWindow.xajax_muestra_sistema('61','fgralesPermisos');$('#menuToggle').click();
      this.$globalData.menuLabel = item.xajax;
      console.log("velueItem: ", this.$globalData.menuLabel);
      // Vue.set(Vue.prototype, '$appName', title)
      this.$router.push({ name: "TEMAS" });
    }
  }
};
</script>
<style>
.containeraf {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 1;
  bottom: 0;
  right: 1;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
