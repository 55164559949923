// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'

// Translations provided by Vuetify
import { es } from 'vuetify/locale'

// import {
//   VDataTable,
//   VDataTableServer,
//   VDataTableVirtual,
// } from "vuetify/labs/VDataTable";

const colors = {
  primary: '#6580a4', // AZUL MARINO
  secondary: '#000F9F', // EJE SEGURIDAD Y PAZ SOCIAL
  accent: '#C8C8AA', // DOCUMENTOS OFICIALES
  info: '#0066FF', // AZUL MEDIO - STAFF DEL GOBERNADOR
  sostenible: '#32AA00', // EJE DESARROLLO ORDENADO Y SOSTENIBLE
  incluyente: '#FF8200', // EJE DESARROLLO ECONÓMICO INCLUYENTE
  error: '#f32929',
  calidad: '#B9C8E7', // EJE EDUCACIÓN DE CALIDAD
  efectivo: '#00A99D', // EJE GOBIERNO HUMANO Y EFECTIVO
  social: '#F45197', // EJE DESARROLLO SOCIAL Y HUMANO
  violet: '#9C27b0',
  pinkmx: '#E91E63',
  pink: '#FF5252',
  infovf: '#00CAE3',
}


export default createVuetify({
  components: {
    // VDataTable,
    // VDataTableServer,
    // VDataTableVirtual,
    ...components,
    ...labsComponents,
  },
  directives,
  locale: {
    locale: 'es',
    fallback: 'es',
    messages: { es },
  },
  theme: {
    themes: {
      dark:{
        dark: true,
        colors: colors
      },
      light: {
        dark: false,
        colors: colors
      },
    },
  },

})
