<template>
  <!-- <v-card> -->
    <!-- <v-layout> -->
      <v-app-bar
        id="default-app-bar"
        app
        class="v-bar--underline"
        color="#101f32"
        :clipped-left="isRTL"
        :clipped-right="!isRTL"
        height="59"
        dark
        fade-img-on-scroll
        :elevation="0"

      >
      <!-- Coso del drawer -->
      <default-drawer-header />

      <!-- <v-divider class="mx-3 mb-2" vertical /> -->
      <!-- Fin del coso del drawer -->
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click.stop="rail = !rail"
        />

        <default-drawer-toggle class="hidden-sm-and-down" />

        <v-toolbar-title class="font-weight-light text-h5" v-text="currentRouteName" />

        <v-spacer />

        <!-- <default-search class="hidden-sm-and-down" /> -->

        <default-go-home />

        <default-notifications />

        <default-account />
      </v-app-bar>
    <!-- </v-layout> -->
  <!-- </v-card> -->
</template>

<script>
import DefaultDrawerHeader from "./widgets/DrawerHeader";
// import { sync } from 'vuex-pathify'
import { mapMutations, mapState } from "vuex";
import DefaultAccount from "./widgets/Account"
import DefaultDrawerToggle from "./widgets/DrawerToggle"
import DefaultGoHome from "./widgets/GoHome"
import DefaultNotifications from "./widgets/Notifications"

export default {
  name: "DefaultBar",

  components: {
    DefaultAccount,
    DefaultDrawerToggle,
    DefaultGoHome,
    DefaultNotifications,
    DefaultDrawerHeader,
    // DefaultSearch: () =>
    //   import(
    //     /* webpackChunkName: "default-search" */
    //     "./widgets/Search"
    //   )
  },
  data() {
    return {
      showMenu: false // modal de notificaciones
    };
  },
  props: ["minNav", "color"],
  created() {
    this.showMenu = this.navbarMinimize;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize();
    }
  },
  computed: {
    ...mapState('app', [
      'drawer',
      'mini',
    ]),
    ...mapState(["isRTL", "isAbsolute"]),
    currentRouteName() {
      return this.$route.name;
    },
  }
};
</script>
