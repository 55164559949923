<template>
  <v-list
    expand
    density="compact"
    nav="true"
    v-bind="$attrs"
    style="position: relative; height: calc(100vh - 75px) !important; overflow: auto; width: 100%; z-index: 4; text-align: start;"
  >
    <template v-for="(item, i) in items" :key="i">
      <div v-if="item.isComponent == false">
        <default-list-group
        v-if="item.items"
        :key="`group-${i}`"
        :item="item"
      />

      <default-list-item-xajax
        v-else
        :key="`item-${i}`"
        :item="item"
      />
      </div>
      <div v-else>
        <default-list-group
        v-if="item.items"
        :key="`group-${i}`"
        :item="item"
      />

      <default-list-item
        v-else
        :key="`item-${i}`"
        :item="item"
      />
      </div>

    </template>
  </v-list>
</template>

<script>
import DefaultListGroup from './ListGroup'
import DefaultListItemXajax from './ListItemXajax'
import DefaultListItem from './ListItem'

  export default {
    name: 'DefaultList',

    components: {
      DefaultListGroup,
      DefaultListItemXajax,
      DefaultListItem,
    },

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
    mounted () {
      // console.log('ey:', this.items);
    },
  }
</script>
