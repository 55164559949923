<template>
  <v-list-group
    :color="gradient !== 1 ? 'white' : undefined"
    :group="group"
    :prepend-icon="item.icon"
    eager
    v-bind="$attrs"
  >
    <template v-slot:activator="{ props }">
      <!-- <v-list-item-icon
        v-if="!item.icon && !item.avatar"
        class="text-caption text-uppercase text-center my-2 align-self-center"
        style="margin-top: 14px"
      >
        {{ title }}
      </v-list-item-icon>

      <v-list-item-avatar v-if="item.avatar">
        <v-img :src="item.avatar" />
      </v-list-item-avatar>

      <v-list-item-content v-if="item.title">
        <v-list-item-title v-text="item.title" />
      </v-list-item-content> -->
      <v-list-item
        v-bind="props"
        :prepend-icon="item.icon"
        :title="item.title"
      ></v-list-item>
    </template>

    <template v-for="(child, i) in item.items" :key="i">
      <div v-if="child.isComponent == false">
        <default-list-group
          v-if="child.items"
          :key="`sub-group-${i}`"
          :item="child"
        />

        <default-list-item-xajax
          v-if="!child.items"
          :key="`child-${i}`"
          :item="child"
        />
      </div>
      <div v-else>
        <default-list-group
          v-if="child.items"
          :key="`sub-group-${i}`"
          :item="child"
        />

        <default-list-item
          v-if="!child.items"
          :key="`child-${i}`"
          :item="child"
        />
      </div>
    </template>
  </v-list-group>
</template>

<script>
import { mapGetters } from "vuex";

import DefaultListItem from "./ListItem";
import DefaultListItemXajax from "./ListItemXajax";

// Utilities
// import { get } from 'vuex-pathify'

export default {
  name: "DefaultListGroup",

  components: {
    DefaultListItem,
    DefaultListItemXajax
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ...mapGetters("user", ["gradient"]),
    group() {
      return this.genGroup(this.item.items);
    },
    title() {
      const matches = this.item.title.match(/\b(\w)/g);

      return matches.join("");
    }
  },

  methods: {
    genGroup(items) {
      // return items.reduce((acc, cur) => {
      //   if (!cur.to) return acc
      //   acc.push(
      //     cur.items
      //       ? this.genGroup(cur.items)
      //       : cur.to.slice(1, -1),
      //   )
      //   return acc
      // }, []).join('|')
    }
  }
};
</script>
