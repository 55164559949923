// Utilities
import { make } from "vuex-pathify";

// Globals
import { IN_BROWSER } from "@/util/globals";

import axios from "axios";
import { axiosSingleton } from "../../plugins/axios";

import { useStorage } from "vue3-storage";
const storage = useStorage();

const state = {
  status: "",
  token: localStorage.getItem("token") || "",
  user: {},
  Cuser: {},
  dark: true,
  drawer: {
    image: 0,
    gradient: 0,
    mini: false,
    rail: false
  },
  rail: true,
  gradients: [
    "rgb(0 0 0 / 58%)",
    "rgb(228, 226, 226, 1), rgba(255, 255, 255, 0.7)",
    "rgb(244, 67, 54, .8), rgba(244, 67, 54, .8)"
  ],
  images: [
    process.env.BASE_URL + "img/cga_png.png",
    process.env.BASE_URL + "img/ssp_2.jpg",
    process.env.BASE_URL + "img/pano-bandera.jpg",
    process.env.BASE_URL + "img/logofspe.png",
    process.env.BASE_URL + "img/gto_escudo.png"
  ],
  notifications: [],
  rtl: false
};

const mutations = {
  ...make.mutations(state),
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, token) {
    state.status = "success";
    state.token = token;
  },
  auth_user(state, user) {
    // console.log("desde user mutations", user)
    state.user = user;
  },
  auth_error(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.token = "";
  }
};

const actions = {
  setUsers: ({ commit }) => {
    // const mdata = this.$route.query
    // if (mdata) {
    //   console.log('setUsers', mdata)
    //   // this.$cookies.set('us', mdata)
    //   // const cokk = this.$cookies.get('us')
    //   // this.name = localStorage.mdata;
    //   // console.log('cookie dsd', cokk)
    //   this.$store.dispatch("doLogin", mdata)
    //   this.store.dispatch("setUsers");
    //   this.$session.start()
    //   this.$session.set('mices', mdata)
    // }
  },
  fetch: ({ commit }) => {
    console.log("desde fecthhhhhh");
    const local = localStorage.getItem("vuetify@user") || "{}";
    const user = JSON.parse(local);

    for (const key in user) {
      commit(key, user[key]);
    }

    if (user.dark === undefined) {
      commit("dark", window.matchMedia("(prefers-color-scheme: dark)"));
    }
  },
  update: ({ state }) => {
    if (!IN_BROWSER) return;

    localStorage.setItem("vuetify@user", JSON.stringify(state));
  },
  async login({ commit }, user) {
    return await new Promise((resolve, reject) => {
      commit("auth_request");
      axiosSingleton
        .getInstance()
        .post("api/obusrt", user)
        .then((resp) => {
          const token = resp.data[0].cnx_id;
          //  const user = resp.data[2][0].usr_email
          // const user = { data: resp.data[2][0], data_p:{cargo:resp.data[3][0].cargo}};
          const user = resp.data[2][0];

          user['cargo'] = resp.data[3][0].cargo
          user['rfc'] = resp.data[3][0].rfc
          user['domicilio'] = resp.data[3][0].domicilio
          user['tipo_sanguineo'] = resp.data[3][0].tipo_sanguineo
          user['unidad_responsable'] = resp.data[3][0].unidad_responsable
          user['fotos'] = resp.data[3][0].fotos
          user['firma'] = resp.data[3][0].firma

          // console.log(resp.data[3][0].cargo)

          localStorage.setItem("token", JSON.stringify(token));
          // localStorage.setItem('user', JSON.stringify(user))

          console.log("storage", storage);
          storage.setStorageSync("user_storage", JSON.stringify(user), 90000000);
          // console.log("desde user store", user)
          axios.defaults.headers.common.Authorization = token;
          commit("auth_success", token);
          commit("auth_user", user);

          resolve(resp);

        })
        .catch((err) => {
          console.log("error");
          commit("auth_error");
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit("logout");
      // localStorage.removeItem('token')
      // localStorage.removeItem('vuetify@user')
      // localStorage.removeItem('user')
      localStorage.clear();

      delete axios.defaults.headers.common.Authorization;
      resolve();
    });
  }
};

const getters = {
  dark: (state, getters) => {
    // console.log('desde getters', getters)
    return state.dark || getters.gradient.indexOf("255, 255, 255") === -1;
  },
  gradient: (state) => {
    return state.gradients[state.drawer.gradient];
  },
  image: (state) => {
    // console.log("image", state.drawer.image)
    return state.drawer.image === ""
      ? state.drawer.image
      : state.images[state.drawer.image];
  },
  isLoggedIn: (state) => {
    return !!state.token;
  },
  authStatus: (state) => {
    return state.status;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
