<template>
   <v-main>
      <router-view :key="$route.path" />
  </v-main>

</template>

<script>
  export default {
    name: 'DefaultView',
  }

</script>
<style>
.container--fluid {
    max-width: 100% !important;
    height: 100% !important;
}
</style>
