<template>
  <v-menu v-model="menu" :close-on-content-click="false" location="start">
    <template v-slot:activator="{ props }">
      <v-btn class="ml-2" min-width="0" color="#fff" text v-bind="props">
        <v-badge bordered color="red" overlap>
          <template v-slot:badge>
            <span>2</span>
          </template>

          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <!-- <v-list
      flat
      nav
    >
        <v-list-item v-for="(n, i) in notifications"
        :key="i"
        link>
          <v-list-item-title>{{ n }} </v-list-item-title>
        </v-list-item>
    </v-list> -->
    <v-card max-width="400" variant="elevated">
      <v-card-text>
        <v-row>
          <v-col>
          <countdown
            starttime="Feb 06, 2024 00:00:00"
            endtime="Feb 06, 2024 11:59:59"
            trans='{
          "eventText":"Aniversario de la Constitución",
         "day":"Días",
         "hours":"Horas",
         "minutes":"Minutos",
         "seconds":"Segundos",
         "expired":"El evento ha finalizado.",
         "running":"Tiempo restante hasta el final del evento.",
         "upcoming":"Tiempo restante hasta el inicio del evento.",
         "status": {
            "expired":"Finalizado",
            "running":"En proceso",
            "upcoming":"Próximamente"
           }}'
          ></countdown>
          <!--  End! Timer Component  -->
        </v-col>
        <v-col>
          <!--  Timer Component  -->
          <countdown
            starttime="Mar 18, 2024 00:00:00"
            endtime="Mar 18, 2024 11:59:59"
            trans='{
          "eventText":"Conmemoración del 21 de marzo. Natalicio de Benito Juárez.",
         "day":"Días",
         "hours":"Horas",
         "minutes":"Minutos",
         "seconds":"Segundos",
         "expired":"El evento ha finalizado.",
         "running":"Tiempo restante hasta el final del evento.",
         "upcoming":"Tiempo restante hasta el inicio del evento.",
         "status": {
            "expired":"Finalizado",
            "running":"En proceso",
            "upcoming":"Próximamente"
           }}'
          ></countdown>

          <!--  End! Timer Component  -->
        </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import Countdown from "../../components/custom/Countdown.vue";

export default {
  name: "DefaultNotifications",
  components: {
    Countdown
  },
  data: () => ({
    menu: false,
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one"
    ]
  })
};
</script>
