// Pathify
import { make } from "vuex-pathify";
import { getSistemas } from "@/api/iniApi";
import { useStorage } from "vue3-storage";
const storage = useStorage();

// Data
const state = {
  drawer: false,
  reloadPage: true,
  drawerImage: true,
  mini: true,
  rail: false,
  items: []
  // hideConfigButton: false,
  //   isPinned: true,
  //   showConfig: false,
  //   sidebarType: "bg-gradient-dark",
  //   isRTL: false,
  //   color: "success",
  //   isNavFixed: false,
  //   isAbsolute: false,
  //   showNavs: true,
  //   showSidenav: true,
  //   showNavbar: true,
  //   showFooter: true,
  //   showMain: true,
  //   isDarkMode: false,
  //   navbarFixed:
  //     "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
  //   absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
};

const mutations = {
  ...make.mutations(state),
  auth_items(state, items) {
    state.items = items;
  },
  auth_usr(state, usr) {
    state.usuario = usr;
  },
  // toggleConfigurator(state) {
  //   state.showConfig = !state.showConfig;
  // },
  navbarMinimize(state) {
    state.rail = !state.rail;
    // state.mini = !state.mini
    // state.mini = this.$vuetify.breakpoint.mdAndDown;
  }
  // navbarFixed(state) {
  //   if (state.isNavFixed === false) {
  //     state.isNavFixed = true;
  //   } else {
  //     state.isNavFixed = false;
  //   }
  // },
  // toggleEveryDisplay(state) {
  //   state.showNavbar = !state.showNavbar;
  //   state.showSidenav = !state.showSidenav;
  //   state.showFooter = !state.showFooter;
  // },
  // toggleHideConfig(state) {
  //   state.hideConfigButton = !state.hideConfigButton;
  // },
  // color(state, payload) {
  //   state.color = payload;
  // },
};

const actions = {
  ...make.actions(state),
  setColor({ commit }, payload) {
    commit("color", payload);
  },
  init: async ({ dispatch }) => {
    //
  },
  async getItems({ commit }) {
    const itms = [
      {
        title: "Principal",
        icon: "mdi-view-dashboard",
        to: "/",
        isComponent: true
      },
      {
        title: "Módulo de bienes",
        icon: "mdi-desk",
        to: "/registros",
        isComponent: true
      },
      {
        title: 'Módulo de atención a auditorias',
        icon: 'mdi-cash-multiple',
        to: '/auditorias',
        isComponent: true,
      },
      // {
      //   title: 'Módulo de Pólizas',
      //   icon: 'mdi-file-sign',
      //   to: '/polizas',
      //   isComponent: true,
      // },
    ];

    // let dataUsr = JSON.parse(storage.getItem("user_storage").data.value)
    // console.log("dataUsr",dataUsr)
    // return await new Promise((resolve, reject) => {
    //   getSistemas(dataUsr.emp_num).then(resp => {
    //     // console.log('get menu', resp)
    //     resp.data.map(e => {

    //       console.log(e.items)
    //       if (e.items.length > 0) {
    //         e.icon = 'mdi-sitemap'
    //       e.isComponent = false

    //         e.items.map(f => {
    //           f.icon = 'mdi-account'
    //           // f.to = '/content/info'
    //           f.isComponent = false
    //           f.xajax = "document.getElementById('iframe1').contentWindow.xajax_muestra_sistema('" + f.id_sistema + "','" + f.sist_div + "');$('#menuToggle').click();"
    //           return f
    //         })

    //         itms.push(e)
    //       }
    //       // else { e.items !== null ||
    //       //   if (e.xajax !== null || e.xajax !== undefined) {
    //       //     e.xajax = "document.getElementById('iframe1').contentWindow.xajax_muestra_sistema('" + e.id_sistema + "','" + e.sist_div + "');$('#menuToggle').click();"
    //       //   }
    //       // }
    //       // return e

    //     })

    //     // console.log(itms)
    //     commit('auth_items', itms)
    //   })
    //   })

    // console.log(itms)
    commit("auth_items", itms);
  }
  // async selectMenu () {

  // },
};

const getters = {
  // tareasRealizadasPorId (state) => (id) => {
  //   return state.tareas.filter(tarea => tarea.id === id)
  // }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
