<template>
  <v-list-item
    lines="one"
    :prepend-avatar="require('@/assets/ICON.webp')"
    nav
  >
    <h2 v-if="!rail" class="text-center min-ssp ml-3 mr-3">
      <strong class="mr-1 font-weight-black">SSP</strong>
      <span class="primary--text">CGA</span>
    </h2>
  </v-list-item>
</template>

<script>
import { mapState } from "vuex";
// Utilities
import { get } from "vuex-pathify";

export default {
  name: "DefaultDrawerHeader",

  computed: {
    version: get("app/version"),
    ...mapState("app", [
      "mini",
      "rail",
    ])
  }
};
</script>
<style>
/*Una vez alcance los 1490px realizas el cambio*/
@media screen and (max-width: 800px) {
  .min-ssp{
    display: none !important;
  }

}
</style>
