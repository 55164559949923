const state = {
    auth: null,
    username: null,
  }
const mutations = {

  doLogin (state, username) {
    state.auth = true
     state.username = username
  },
  doLogout (state) {
    state.auth = false
     state.username = null
  },
}
const actions = {

  doLogin ({ commit }, username) {
    // console.log('vuex ini')

    commit('doLogin', username)
  },
  doLogout ({ commit }) {
    commit('doLogout')
  },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
  }
