<template>
    <div
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

  <script>
  import * as echarts from 'echarts'
  import debounce from '@/util/charts'

  require('echarts/theme/macarons') // echarts theme

  export default {
    name: 'OptionChart',
    props: {
      className: {
        type: String,
        default: 'chart',
      },
      width: {
        type: String,
        default: '100%',
      },
      height: {
        type: String,
        default: '100%',
      },
      chartData: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      chart: null,
    }),
    watch: {
      chartData: {
        deep: true,
        handler (val) {
          this.setOptions(val)
        },
      },
    },
    mounted () {
      this.initChart()
      this.resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.resizeHandler)
    },
    beforeUnmount () {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      setOptions (option = {
        tooltip: {
          trigger: 'axis',
        },
      }) {
        this.chart.setOption(option)
      },
      initChart () {
        this.chart = echarts.init(this.$el, 'macarons')
        this.setOptions(this.chartData)
      },
    },
  }
  </script>
