import { createRouter, createWebHistory } from "vue-router";
// import Dashboard from "../views/bootstrap/Dashboard.vue";
import DefaultLayout from '@/layouts/Index.vue'
import store from '../store'
//import tikets from '../views/Tikets.vue'

const routes = [
  {
      path: "/",
      name: "/",
      component: DefaultLayout,
      children: [
        {
          path: "/",
          name: "Principal",
          component: () => import('@/views/Dashboard.vue'),
          props: { default: true },
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/registros",
          name: "Módulo de bienes",
          component: () => import('@/views/Bienes.vue'),
          props: { default: true },
          meta: {
            requireAuth: true,
          },
        },
        {
          path: '/content',
          name: 'TEMAS',
          props: { default: true },
          component: () => import('@/views/oldsia/ContentSIA'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/auditorias",
          name: "Módulo de atención de auditorías",
          component: () => import('@/views/Auditorias.vue'),
          props: { default: true },
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/polizas",
          name: "Pólizas",
          component: () => import('@/views/Polizas.vue'),
          props: { default: true },
          meta: {
            requireAuth: true,
          },

        },
        {
          path: '/tickets',
          name: "Tickets",
          component: () => import('@/views/Tikets.vue'),
          hidden: false,
        },
        {
          path: '/licencias',
          name: "Licencias",
          component: () => import('@/views/Licencias.vue'),
          hidden: false,
        },
        {
          path: '/adminmural',
          name: "AdminMural",
          component: () => import('@/views/AdminMural.vue'),
          hidden: false,
        },
      ]
  },
  {
    name: 'SignIn',
    path: '/signin',
    component: () => import('@/views/auth/SignIn.vue'),
    hidden: true,
  },
  {
    path: '/registration',
    component: () => import('@/views/auth/SignUp.vue'),
    hidden: true,
  },
  {
  path: '/mantenimiento',
  component: () => import('@/views/Mantenimiento.vue'),
  hidden: false,
},
{
  path: '/mural',
  component: () => import('@/views/Mural.vue'),
  hidden: false,
}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  if(to.href == "/signin" && await store.getters['user/isLoggedIn']){
    next('/')
  }

  if (to.matched.some(record => record.meta.requireAuth)) {
    // console.log('logueadodd', store.getters)
    if (await store.getters['user/isLoggedIn']) {
      next()
      return
    }
    next('/signin')
  } else {
    next()
  }
})

export default router;
