import { axiosSingleton } from '../plugins/axios'

export function getSistemas (emp) {
    // console.log('gg2')
    return axiosSingleton.getInstance().get(`api/UsuariosController/GetSistemas/${emp}`)
}

export function getcump (emp) {
    // console.log('gg3')
    // return axiosSingleton.getInstance().get('UsuariosController/dashboard')
    return axiosSingleton.getInstance().get(`api/dashboard/${emp}`)
    //http://127.0.0.1:8000/api/dashboard
}


// export function getConsultaSolicitudes (busqueda) {
//     return axiosSingleton.getInstance().get(`getTbSolicitudes/${busqueda}`)
// }
