<template>
    <v-sheet class="mx-auto mt-10 border-radius-xl card-shadow">
      <v-col cols="12">
        <v-sheet
        :color="imagen ? '' : color"
        :width="fullHeader ? '100%' : 'fit-content'"
        class="mt-n8 rounded-t-lg"
        elevation="2"
        max-width="100%"
      >
        <v-theme-provider
          v-if="hasHeading"
          dark
        >
          <div
            v-if="icon"
            :class="iconSmall ? 'pa-7' : 'pa-8'"
          >
            <v-icon
              :size="large = !iconSmall ? 'large':'small'"
              :icon="icon"
            />
          </div>

          <slot name="heading" />

          <div
            v-if="heading"
            class="text-h4 white--text pa-2 v-card--material__title"
          >
            {{ heading }}
          </div>
        </v-theme-provider>
      </v-sheet>
      </v-col>

      <div
        v-if="hasTitle"
        :class="fullHeader ? 'pt-4' : 'pl-3'"
        class="text-h5 v-card--material__title"
      >
        <slot />

        <template v-if="title">
          {{ title }}
        </template>

        <div class="text-subtitle-1 mb-n4">
          <slot name="subtitle" />

          <template v-if="subtitle">
         {{ subtitle }}
          </template>
        </div>
      </div>



    <slot />

    <template v-if="$slots.actions">
      <v-divider class="mt-2 mx-4" />

      <v-card-actions class="px-4 text-caption grey--text">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-sheet>
</template>

<script>
// import AppCard from "../app/Card.vue";
  export default {
    name: 'MaterialCard',
    components: {
      // AppCard,
  },
    props: {
      color: String,
      fullHeader: Boolean,
      heading: String,
      icon: String,
      iconSmall: Boolean,
      subtitle: String,
      title: String,
      imagen: Boolean,
    },

    computed: {
      hasHeading () {
        return !!(
          this.icon ||
          this.heading ||
          this.$slots.heading
        )
      },
      hasTitle () {
        return !!(
          this.title ||
          this.subtitle ||
          this.$slots.title ||
          this.$slots.subtitle
        )
      },
    },
  }
</script>

<style>
.border-radius-xl {
  border-radius: 0.75rem !important;
  border-width: thin;
}

.shadow-dark {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
}

.v-application .mt-n8 {
  margin-top: -32px !important;
}

.card-shadow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.v-application .text-body {
  color: #7b809a !important;
}

.v-application .font-weight-light {
  font-weight: 300 !important;
}

.v-application--is-ltr .ms-1 {
  margin-left: 4px !important;
}
</style>
