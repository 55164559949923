<template>
  <v-container>
    <v-row align="center">
      <v-col
        v-for="(link, i) in links"
        :key="i"
        class="text-center"
        cols="6"
        md="auto"
      >
        <a
          :href="link.href"
          class="text-decoration-none text-uppercase text-caption font-weight-regular"
          rel="noopener"
          target="_blank"
          v-text="link.text"
        />
      </v-col>

      <v-spacer class="hidden-sm-and-down" />

      <v-col
        cols="12"
        md="auto"
      >
        <div class="text-body-1 font-weight-light pt-6 pt-md-0 text-center">
          &copy; {{ new Date().getFullYear() }}, Gobierno del Estado de
          Guanajuato. Versión 1.0.0
          <!-- <a
            href="https://vuetifyjs.com/en/about/meet-the-team/#company"
            class="text-decoration-none"
          >Vuetify</a> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'LinksComponent',

    data: () => ({
      links: [
        {
          href: 'https://seguridad.guanajuato.gob.mx/licencias_conducir/',
          text: 'SSP Licencias',
        },
        {
          href: 'https://transporte.guanajuato.gob.mx/wp-content/themes/grandezagto_wp_v1_6/tar/',
          text: 'Tarjetones',
        },
        {
          href: 'https://www.gob.mx/curp/',
          text: 'CURP',
        },
        {
          href: 'https://licenciasdeconducir.guanajuato.gob.mx',
          text: 'Portal Licencias',
        },
      ],
    }),
  }
</script>

<style lang="sass" scoped>
a
  color: inherit !important
</style>
