import axios from "axios";
// import store from '@/store'

export const axiosSingleton = (function createSingleton() {
  let instance = null;

  async function createInstance() {
    instance = axios.create({
      // baseURL: 'https://api-sia.ws-ssp.guanajuato.gob.mx/',
      baseURL: process.env.NODE_ENV === 'production' ? 'https://api-sia.ws-ssp.guanajuato.gob.mx/' : 'http://10.10.135.140:8000/',
      // withCredentials: false,
      headers: {
        Accept: 'application/json, multipart/form-data',
        'Content-Type': 'application/json',
      },
    })
    window.axiosInstance = instance
  }

  function getInstance() {
    if (!instance) {
      this.createInstance();
    }
    return instance;
  }

  return {
    createInstance,
    getInstance
  };
})();
//HANA
export const axiosSingletonC = (function createSingleton() { 
  let instance = null;

  async function createInstance() {
    instance = axios.create({
      //baseURL: 'https://api-sia.ws-ssp.guanajuato.gob.mx/',http://10.10.135.140:8585/API/
      baseURL: process.env.NODE_ENV === 'production' ? 'https://api-bienes.ws-ssp.guanajuato.gob.mx/API/' : 'https://localhost:44316/API/',
      // withCredentials: false
      headers: {
        Accept: 
      
        'application/json, multipart/form-data',
        'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
      },
    })
    window.axiosInstance = instance
  }

  function getInstance() {
    if (!instance) {
      this.createInstance();
    }
    return instance;
  }

  return {
    createInstance,
    getInstance
  };
})();

